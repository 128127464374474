import * as React from 'react'

import HomeContentLayout from '@/components/layout/home-content-layout'
import useLanguage from '@/utils/language/language-hook'
import { investingInsightsPath } from '@/route/Investing-Insights'
import InvestingInsightsNav from '@/components/shape/investing-insights/investing-insights-nav'
import InvestingInsightsContents from '@/components/shape/investing-insights/investing-insights-contents'
import useInvestingInsightsWithTags from '@/service/investing-insights/investing-insights-with-tags-hook'
import { HomeContentSpacerX } from '@/components/layout/home-content-layout/styled'
import { SpacerY } from '@/design-system/spacer'
import HomeContentLayoutLink from '@/components/layout/home-content-layout/home-content-layout-link'

const InvestingInsights = () => {
  const { t } = useLanguage('main')
  const { tagId, handleTagClick, investingInsightTagsProps, investingInsightsProps } =
    useInvestingInsightsWithTags()
  const moreView = React.useMemo(
    () => (
      <HomeContentLayoutLink
        href={tagId ? investingInsightsPath({ tagId }) : '#'}
        gaCategory="HOME"
        gaAction="HOME_INVESTING-INSIGHTS_CLICK"
        gaLabel={`HOME_INVESTING-INSIGHTS_VIEW`}>
        {t('common:showMore')}
      </HomeContentLayoutLink>
    ),
    [t, tagId],
  )
  const investingInsights = React.useMemo(
    () => investingInsightsProps.investingInsights?.slice(0, 4), // home에서 4개 제한해서 보여줌
    [investingInsightsProps.investingInsights],
  )

  return (
    <HomeContentLayout title={t('investmentInsights.title')} rightContent={moreView}>
      <InvestingInsightsNav
        tagId={tagId}
        loading={investingInsightTagsProps.loading}
        investingInsightTags={investingInsightTagsProps.investingInsightTags}
        moreLoadingElRef={investingInsightTagsProps.moreLoadingElRef}
        isShowMoreLoading={investingInsightTagsProps.isShowMoreLoading}
        onTagClick={handleTagClick}
      />
      <SpacerY size={20} />
      <HomeContentSpacerX>
        <InvestingInsightsContents
          loading={investingInsightTagsProps.loading || investingInsightsProps.loading}
          investingInsights={investingInsights}
          moreLoadingElRef={investingInsightsProps.moreLoadingElRef}
          isShowMoreLoading={investingInsightsProps.isShowMoreLoading}
        />
      </HomeContentSpacerX>
    </HomeContentLayout>
  )
}

export default InvestingInsights
