import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const StyledStock = styled.div`
  position: relative;
  display: inline-block;
  background: var(--linear-gradient-37-27);
  padding: var(--spacing-legacy-25);
  padding-bottom: var(--spacing-legacy-30);
  border-radius: var(--border-radius-6);
  width: 8rem;

  @media (max-width: 767px) {
    width: auto;
    padding: var(--spacing-legacy-20);
    padding-bottom: var(--spacing-legacy-25);
    flex-basis: ${getSize(80)};
    flex-shrink: 0;
  }

  a {
    width: 100%;
    display: inline-block;

    > *:not(.image):not(.d-day) {
      margin-top: var(--spacing-legacy-10);
    }
  }

  .asset-image {
    width: var(--spacing-legacy-60);
    height: var(--spacing-legacy-60);
  }

  .name {
    .title {
      color: var(--color-primary);
      font-size: var(--font-size-18);
    }

    .sub-title {
      display: none;
      font-size: var(--font-size-18);
      margin-top: var(--spacing-legacy-5);
      width: 90px;

      @media (max-width: 767px) {
        width: ${getSize(70)};
      }
    }
  }

  .yield {
    .label {
      font-size: var(--font-size-12);
    }

    .amount {
      margin-top: var(--spacing-legacy-5);
      font-size: var(--font-size-16);
      font-weight: var(--font-bold);
    }
  }

  .tags {
    position: absolute;
    left: var(--spacing-legacy-25);
    bottom: var(--spacing-legacy-10);
    white-space: nowrap;

    @media (max-width: 767px) {
      left: var(--spacing-legacy-20);
    }

    > *:not(:first-child) {
      margin-left: var(--spacing-legacy-5);
    }

    .--text-low-level {
      font-size: var(--font-size-12);
    }
  }

  .d-day {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--spacing-legacy-5) var(--spacing-legacy-10);
    border-top-right-radius: var(--border-radius-6);
    font-size: var(--font-size-13);
    background-color: var(--color-gray-75);

    &.today {
      background-color: var(--color-scondary);
    }
  }
`

export default StyledStock
