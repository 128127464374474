import * as React from 'react'

// 배당킹, 배당귀족 주식들을 제공하는 API

const serverURL = process.env.NEXT_PUBLIC_THE_RICH_STOCK_SERVER_URL

const majorDividendStocksCategoryPath =
  '/MAJOR_DIVIDEND_STOCKS_CATEGORY/majorDividendStocksCategory.json'

type ResponseData = {
  dividendKing: string[]
  dividendAristocrats: string[]
}

const initData = { dividendKing: [], dividendAristocrats: [] }

export default async function majorDividendStocksCategory(): Promise<ResponseData> {
  return fetch(`${serverURL}${majorDividendStocksCategoryPath}`)
    .then((data) => data.json())
    .catch(() => initData)
}

export const useMajorDividendStocksCategory = (): ResponseData => {
  const [data, setData] = React.useState<ResponseData>(initData)

  React.useEffect(() => {
    majorDividendStocksCategory().then(setData).catch(setData)
  }, [])

  return { ...data }
}
