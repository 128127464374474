import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'
import Image from '@/components/common/image'
import useReducerBoolean from '@/utils/reducer-boolean-hook'
import NewsKeywordsNotificationModal from '@/components/shape/news-keywords-notification/modal'
import { openSigninModalWithToast } from '@/service/sign-in/store'

import StyledTodayNewsNotification from './styled'

const TodayNewsNotification = () => {
  const { t } = useLanguage('main')
  const [isOpen, setIsOpen] = useReducerBoolean()

  return (
    <>
      <StyledTodayNewsNotification
        className="--flex-x-center"
        onClick={() => openSigninModalWithToast(setIsOpen)}>
        <div className="alarm-image">
          <Image src="/images/icons/alarm-primary.svg" />
        </div>
        {t('todayNews.notification')}
      </StyledTodayNewsNotification>
      <NewsKeywordsNotificationModal isOpen={isOpen} onClose={setIsOpen} />
    </>
  )
}

export default TodayNewsNotification
