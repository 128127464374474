import { getI18n } from 'react-i18next'

import { LocaleEnum } from '@/types/locale'

import isNumberTickerAndKorean from './is-number-ticker-and-korean'

type Props = {
  stock: {
    country?: string | null
    ticker?: string | null
    companyName?: {
      en?: string | null
      ko?: string | null
    } | null
  }
  reverse?: boolean
  locale?: LocaleEnum
}

// 티커 혹은 종목이름 반환
// US: TICKER
// KR: 종목이름
// LEGACY: portfolio > getAssetName > isCompanyAsset
export default function tickerOrName({
  stock,
  reverse = false, // reverse: true 일 경우 US는 이름을, KR은 ticker를 반환
  locale = getI18n().language as LocaleEnum,
}: Props) {
  // 한국 주식 ticker 중에 00000K 처럼 끝나는 경우가 있음
  const { ticker, country, companyName } = stock
  if (ticker && (reverse || isNumberTickerAndKorean({ ticker, country }))) {
    const subLocale = locale === LocaleEnum.en ? LocaleEnum.ko : LocaleEnum.en
    return companyName?.[locale] || companyName?.[subLocale] || ''
  } else {
    return ticker || ''
  }
}
