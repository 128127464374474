import styled from 'styled-components'

const StyledPopupsDesktop = styled.div`
  background: linear-gradient(180deg, var(--color-gray-46) 0%, var(--color-gray-19) 80%);

  .bottom-buttons {
    background-color: var(--color-gray-19);

    button {
      width: 50%;
      color: var(--color-gray-128);
      padding: var(--spacing-legacy-30) var(--spacing-legacy-20);
      font-size: var(--font-size-16);
    }
  }
`

export default StyledPopupsDesktop
