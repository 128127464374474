import * as React from 'react'
import dynamic from 'next/dynamic'

import { useMobileSize } from '@/utils/browser/resize-hook'
import withClientOnly from '@/components/common/client-only/hoc'
import useLanguage from '@/utils/language/language-hook'
import { useAuthTokenStore } from '@/service/auth/store'
import useAds from '@/service/advertisement/ads-hook'
import ChannelService from '@/components/third-party/channel-talk'

import MobilePopups from './popups-mobile'
import DesktopPopups from './popups-desktop'

const MobileAppGuider = dynamic(() => import('@/components/shape/mobile-app-guider'))

const Popups = () => {
  const { language } = useLanguage()
  const { isMobile } = useMobileSize(null)
  const { token } = useAuthTokenStore()
  const { ads, doNotShowAd, handleClickAd, handleCloseAd, loading } = useAds({
    language,
    isMobile,
    token,
  })

  // 채널톡 처리(모바일에서 버튼에 간섭이 생겨 대응)
  React.useEffect(() => {
    if (loading) return
    if (ads.length === 0) {
      ChannelService.showChannelButton()
    } else {
      ChannelService.hideChannelButton()
    }
  }, [ads.length, loading])

  return (
    <>
      {isMobile === true && ads.length === 0 && <MobileAppGuider />}
      <MobilePopups
        isOpen={isMobile === true && !!ads.length}
        popups={ads}
        doNotShowAd={doNotShowAd}
        onCloseAd={handleCloseAd}
        onClickAd={handleClickAd}
      />
      <DesktopPopups
        isOpen={isMobile === false && !!ads.length}
        popups={ads}
        doNotShowAd={doNotShowAd}
        onCloseAd={handleCloseAd}
        onClickAd={handleClickAd}
      />
    </>
  )
}

export default withClientOnly(Popups)
