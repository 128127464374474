import * as React from 'react'
import { Spacing } from '@therich-team/design-system'

import HomeContentLayout from '@/components/layout/home-content-layout'
import useLanguage from '@/utils/language/language-hook'
import { useMobileSize } from '@/utils/browser/resize-hook'
import NewsCountries from '@/components/shape/news/news-countries'
import TodayNewsNav, { menu, newsPaths } from '@/components/shape/news/news-nav'
import { getAuthToken } from '@/service/auth/store'
import {
  HomeContentSpacerX,
  MobileMoreViewLink,
} from '@/components/layout/home-content-layout/styled'
import HomeContentLayoutLink from '@/components/layout/home-content-layout/home-content-layout-link'

import TodayNewsNotification from './today-news-notification'
import TodayNewsList from './today-news-list'

const TodayNews = () => {
  const { t } = useLanguage('main')
  const { isMobile } = useMobileSize(false)
  const [selectedMenu, setSelectedMenu] = React.useState(menu[0])
  const [countryCode, setCountryCode] = React.useState('')
  const todayNewsNotification = React.useMemo(() => <TodayNewsNotification />, [])
  const moreView = React.useMemo(
    () =>
      isMobile ? (
        <MobileMoreViewLink
          href={newsPaths[selectedMenu]}
          gaCategory="HOME"
          gaAction="HOME_NEWS-MORE_CLICK"
          gaLabel={`HOME_NEWS-MORE_${newsPaths[selectedMenu].toLocaleUpperCase()}`}>
          {t('common:showMore')}
        </MobileMoreViewLink>
      ) : (
        <div className="--flex-x-center">
          {todayNewsNotification}
          <HomeContentLayoutLink
            href={newsPaths[selectedMenu]}
            gaCategory="HOME"
            gaAction="HOME_NEWS-MORE_CLICK"
            gaLabel={`HOME_NEWS-MORE_${newsPaths[selectedMenu].toLocaleUpperCase()}`}>
            {t('common:showMore')}
          </HomeContentLayoutLink>
        </div>
      ),
    [isMobile, selectedMenu, t, todayNewsNotification],
  )
  const rightContent = React.useMemo(
    () => (isMobile ? <NewsCountries onChange={setCountryCode} /> : moreView),
    [isMobile, moreView],
  )

  React.useEffect(() => {
    if (!getAuthToken()) {
      setSelectedMenu('today-pick')
    }
  }, [])

  return (
    <HomeContentLayout title={t('todayNews.title')} rightContent={rightContent}>
      <div className="--mobile-only">{todayNewsNotification}</div>
      <Spacing y="500" className="--mobile-only" />
      <TodayNewsNav
        selectedMenu={selectedMenu}
        onChangeMenu={setSelectedMenu}
        onChangeCountry={!isMobile && setCountryCode}
      />
      <Spacing y="500" />
      <HomeContentSpacerX>
        <TodayNewsList selectedMenu={selectedMenu} countryCode={countryCode} moreView={moreView} />
      </HomeContentSpacerX>
    </HomeContentLayout>
  )
}

export default TodayNews
