import * as React from 'react'

import type { AdContentFieldsFragment } from '@/gql/generated-api1'
import Link from '@/components/common/link'
import ImageWithFallback from '@/components/common/image-with-fallback'

import StyledPopupContent from './styled'

type Props = {
  adId: string
  url: string
  content: AdContentFieldsFragment
  onClick: () => void
}

const PopupContent = ({ adId, url, onClick, content }: Props) => {
  const isInternalURL = /(www|link)\.therich\..+/.test(url)
  return (
    <StyledPopupContent>
      <Link
        href={url}
        target={isInternalURL ? undefined : '_blank'}
        onClick={onClick}
        gaCategory="AD"
        gaAction="AD_POPUP_CLICK"
        gaLabel={`AD_POPUP_${adId}`}>
        <ImageWithFallback src={content.contentUrl} alt={content.name || ''} loading="eager" />
      </Link>
    </StyledPopupContent>
  )
}

export default PopupContent
