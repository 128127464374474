import * as React from 'react'

import { useThisWeekDividendStocksQuery } from '@/gql/generated-api1'
import { useMajorDividendStocksCategory } from '@/api/stock/major-dividend-stocks-category'
import useLanguage from '@/utils/language/language-hook'
import HomeContentLayout from '@/components/layout/home-content-layout'
import HomeContentLayoutLink from '@/components/layout/home-content-layout/home-content-layout-link'

import Stocks from './stocks'

const ThisWeekDividendStocks = () => {
  const { loading, error, data } = useThisWeekDividendStocksQuery()
  const { dividendKing, dividendAristocrats } = useMajorDividendStocksCategory()
  const { t } = useLanguage('main')
  const link = React.useMemo(
    () => (
      <HomeContentLayoutLink
        href="/dividend-calendar"
        gaCategory="HOME"
        gaAction="HOME_DIVIDEND-CALENDAR_CLICK">
        {t('common:showMore')}
      </HomeContentLayoutLink>
    ),
    [t],
  )

  return (
    <HomeContentLayout title={t('dividends.title')} rightContent={link}>
      <Stocks
        loading={loading}
        error={error}
        dividendKing={dividendKing}
        dividendAristocrats={dividendAristocrats}
        dividends={data?.thisWeekDividendStocks}
        loadingBarHeight={186}
      />
    </HomeContentLayout>
  )
}

export default ThisWeekDividendStocks
