import styled from 'styled-components'

const StyledHome = styled.div`
  position: relative;

  .chart {
    display: flex;
    display: inline-block;
    width: 530px;
    background-color: var(--color-gray-27);
  }

  .top-contents {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    flex-shrink: 0;

    @media (max-width: 767px) {
      width: 100%;
    }

    .mbti,
    .portfolios {
      display: inline-block;
      width: 50%;
      height: 120px;

      @media (max-width: 767px) {
        width: 100%;
        height: auto;
      }
    }

    .mbti {
      vertical-align: top;
    }
  }

  @media (min-width: 1400px) {
    .advertisement-container {
      position: absolute;
      top: 0;
      right: calc(-1 * var(--spacing-legacy-20));
      height: 100%;
      /* background: red; */
      transform: translateX(100%);

      div {
        top: var(--spacing-legacy-20);
        width: 13rem;
        height: 45rem;
      }
    }
  }
`

export default StyledHome
