import styled from 'styled-components'

const StyledPopupContent = styled.div`
  a {
    display: inline-block;
    width: 100%;

    img {
      object-fit: contain;
      width: 100%;
    }
  }
`

export default StyledPopupContent
