import styled from 'styled-components'

import { getSizeBy375 } from '@/utils/style/size'

const StyledRecommendedPortfolios = styled.div`
  a {
    @media (max-width: 767px) {
      :first-child {
        margin-left: var(--spacing-legacy-20);
      }
      :last-child {
        margin-right: var(--spacing-legacy-20);
      }
    }

    .image {
      margin: auto;
      width: 8.5rem;
      height: 8.5rem;

      @media (max-width: 767px) {
        width: ${getSizeBy375(65)};
        height: ${getSizeBy375(65)};
      }
    }

    span {
      color: var(--color-gray-128);
      padding-top: var(--spacing-legacy-20);
      text-align: center;
      display: block;
    }
  }
`

export default StyledRecommendedPortfolios
