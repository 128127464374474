import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Link from '@/components/common/link'
import AssetImage from '@/components/shape/asset-image'
import type { DDay } from '@/service/dividend/dividend-d-day'
import threeComma from '@/utils/number/three-comma'

import StyledStock from './styled'

type Props = {
  dDay: DDay
  ticker: string
  country: string
  name: string
  logoUrl: string
  isKing: boolean
  isAristocrats: boolean
  dividendRatioPercent: string
}

const Stock = ({
  dividendRatioPercent,
  dDay,
  ticker,
  country,
  name,
  logoUrl,
  isKing,
  isAristocrats,
}: Props) => {
  const { t } = useTranslation('dividend')

  return (
    <StyledStock>
      <Link
        href={`/stock?ticker=${ticker}&country=${country}`}
        gaCategory="HOME"
        gaAction="HOME_DIVIDEND-STOCK_CLICK"
        gaLabel={`HOME_DIVIDEND-STOCK_${country}-${ticker}`}>
        {dDay !== null && (
          <span className={`d-day${dDay === 0 ? ' today' : ''}`}>
            {dDay === 0 ? t('today') : `D-${dDay}`}
          </span>
        )}
        <div className="asset-image">
          <AssetImage imageUrl={logoUrl} alt={ticker} />
        </div>
        <div className="name">
          <div className="--truncate title">{name}</div>
        </div>
        <div className="yield">
          <div className="label --text-low-level">{t('marketPriceYield')}</div>
          <div className="amount">{threeComma({ value: dividendRatioPercent })}%</div>
        </div>
        <div className="tags">
          {isKing && <span className="--text-low-level">#{t('king')}</span>}
          {isAristocrats && <span className="--text-low-level">#{t('aristocrat')}</span>}
        </div>
      </Link>
    </StyledStock>
  )
}

export default Stock
