import dateValidation from '@/utils/date/validation'

type Props = {
  exDividendDate: string // 2020-01-01
  dDate?: Date // D-day에 해당하는 기준일
}

type Day = 0 | 1 | 2 | 3 | 4 | 5 | 6 // 일 ~ 토

// 요일에 따른 d-day 보정
function getDDayByDayWeek({ diffDays, day }: { diffDays: number; day: Day }) {
  switch (day) {
    case 0:
      // 일요일
      return diffDays - 1
    case 1:
      // 월요일
      return diffDays - 2
    default:
      return diffDays
  }
}

export type DDay = number | null

// 배당 받을 수 있는 날짜 d-day 계산(배당락일 전날까지 매수해야 배당금 받음)
// 잡설: 한국에서 해외거래할 때는 거래일이 몇일 더 걸릴 수 있으므로 최소 배당락일 3일전에는 매수하길 권장
export default function dividendDDay({ exDividendDate, dDate = new Date() }: Props): DDay {
  const _exDividendDate = new Date(exDividendDate)

  if (!dateValidation(_exDividendDate)) {
    return null
  }

  const diffDays = (_exDividendDate.getTime() - dDate.getTime()) / (1000 * 60 * 60 * 24)
  const day = _exDividendDate.getDay() as Day
  const dDay = Math.floor(getDDayByDayWeek({ diffDays, day })) // 소수점 버림

  // dDay === 0 -> D-day
  // dDay === 3 -> D-3

  return dDay
}
