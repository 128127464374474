import dynamic from 'next/dynamic'

const TradingViewWidget = dynamic(() => import('./index'), { ssr: false })

type Props = {
  symbol: string
}

const DynamicTradingview = ({ symbol }: Props) => {
  return <TradingViewWidget symbol={symbol} />
}

export default DynamicTradingview
