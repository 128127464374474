import * as React from 'react'
import ReactSlick, { CustomArrowProps, Settings } from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Image from '@/components/common/image'
import { useMobileSize } from '@/utils/browser/resize-hook'

import StyledCarousel from './styled'

function NextArrow(props: CustomArrowProps) {
  const { className, onClick } = props

  return (
    <button
      className="--flex-x-y-center next"
      disabled={/slick-disabled/.test(className || '')}
      onClick={onClick}>
      <div>
        <Image
          src="/images/icons/show.svg"
          alt="prev"
          layout="responsive"
          width={100}
          height={100}
        />
      </div>
    </button>
  )
}

function PrevArrow(props: CustomArrowProps) {
  const { className, onClick } = props

  return (
    <button
      className="--flex-x-y-center  prev"
      disabled={/slick-disabled/.test(className || '')}
      onClick={onClick}>
      <div>
        <Image
          src="/images/icons/show.svg"
          alt="prev"
          layout="responsive"
          width={100}
          height={100}
        />
      </div>
    </button>
  )
}

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 7.5,
  slidesToScroll: 6,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

type Props = {
  children: React.ReactNode
  rootRef?: React.RefObject<HTMLDivElement>
  sliderRef?: React.LegacyRef<ReactSlick>
} & Settings

export default function Carousel({ children, rootRef, sliderRef, ...props }: Props) {
  const { isMobile } = useMobileSize()

  return (
    <StyledCarousel ref={rootRef} className={isMobile ? '--horizontal-scroll' : undefined}>
      {isMobile && children}
      {!isMobile && (
        <ReactSlick ref={sliderRef} {...settings} {...props}>
          {children}
        </ReactSlick>
      )}
    </StyledCarousel>
  )
}
