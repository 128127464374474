import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

export const StyledError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;

  @media (max-width: 767px) {
    margin: ${getSize(20)};
    font-size: ${getSize(20)};
  }

  > *:not(:first-child) {
    margin-top: 10px;

    @media (max-width: 767px) {
      margin-top: ${getSize(10)};
    }
  }
`
