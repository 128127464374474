import { LocalStorageEnum } from '@/constants/localstorage'
import { getLocalStorage, setLocalStorage } from '@/utils/browser/localstorage'

type InvisibleAds = Record<string, number>

/**
 * 로컬 스토리지 기반으로 n일동안 보지 않기 처리된 광고
 */
export function getInvisibleAds() {
  const invisibleAds = getLocalStorage({
    key: LocalStorageEnum.InvisibleAds,
    type: 'object',
    fallbackValue: {},
  }) as InvisibleAds

  return clearInvisibleAds(invisibleAds)
}

/**
 * 로컬스토리지에 저장된 광고 데이터중 시간이 만료된 데이터는 제거
 */
function clearInvisibleAds(invisibleAds: InvisibleAds) {
  const filteredInvisibleAds = Object.entries(invisibleAds).reduce(
    (invisibleAds: InvisibleAds, [adId, expiryMs]) => {
      if (new Date().getTime() < expiryMs) {
        invisibleAds[adId] = expiryMs
      }
      return invisibleAds
    },
    {},
  )
  return filteredInvisibleAds
}

export function setInvisibleAds(ads: { adId: string; ms: number }[]) {
  const invisibleAds = getInvisibleAds()
  return setLocalStorage({
    key: LocalStorageEnum.InvisibleAds,
    value: ads.reduce((acc: InvisibleAds, ad) => {
      acc[ad.adId] = ad.ms
      return acc
    }, invisibleAds),
  })
}
