import styled from 'styled-components'

import Link from '@/components/common/link'

export const HomeContentSpacerX = styled.div`
  @media (max-width: 767px) {
    margin: 0 var(--spacing-legacy-15);
  }
`

export const MobileMoreViewLink = styled(Link)`
  display: block;
  padding: var(--spacing-legacy-12) var(--spacing-legacy-20);
  background-color: var(--color-gray-32);
  border-radius: var(--border-radius-12);
  text-align: center;

  @media (min-width: 768px) {
    display: none;
  }
`

const StyledHomeContentLayout = styled.div`
  border-radius: var(--border-radius-6);

  @media (max-width: 767px) {
    border-radius: 0;
  }

  .header {
    @media (max-width: 767px) {
      padding: 0 var(--spacing-legacy-15);
    }
  }
`

export default StyledHomeContentLayout
