import * as React from 'react'
import Big from 'big.js'
import { Spacing } from '@therich-team/design-system'

import { CompanyDetailFieldsFragment, useCompanyDetailByIdsQuery } from '@/gql/generated-api1'
import { DividendHistoryFieldsFragment, useDividendHistoriesQuery } from '@/gql/generated-api2'
import indexingDividendHistoiresByCompanyId from '@/service/dividend/indexing-dividend-histories-by-company-id'
import { oneYearDividendsAmountAsStock } from '@/service/dividend/one-year-dividends-amount'
import tickerOrName from '@/service/stock/ticker-or-name'
import { getRecentStocks } from '@/service/home/recent-stock'
import threeComma from '@/utils/number/three-comma'
import useLanguage from '@/utils/language/language-hook'
import Carousel from '@/components/common/carousel'
import Link from '@/components/common/link'
import AssetImage from '@/components/shape/asset-image'
import Loading from '@/components/common/loading'
import useCurrencyExchanges from '@/service/currency/currency-exchanges-hook'

import StyledRecentStocks, { StyledStock } from './styled'

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  arrows: false,
  autoplay: true,
}

const Stock = ({
  company,
  dividendHistories,
}: {
  company: CompanyDetailFieldsFragment
  dividendHistories?: DividendHistoryFieldsFragment[]
}) => {
  const { language } = useLanguage()
  const { exchanges } = useCurrencyExchanges()
  const { ticker, country, needTtm, companyPrice } = company
  const price = companyPrice?.price || 0
  const oneYearDividendAmount = React.useMemo(() => {
    return dividendHistories
      ? oneYearDividendsAmountAsStock({
          dividends: dividendHistories,
          toCurrencySymbol: company.currency?.symbol as string,
          needTtm: !!needTtm,
        })
      : 0
    // exchanges 필수
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchanges, company.currency?.symbol, dividendHistories, needTtm])
  const marketDividendRatioPercent =
    +price > 0 ? Big(oneYearDividendAmount).div(price).mul(100).toString() : 0

  return (
    <StyledStock key={ticker}>
      <Link
        href={`/stock?ticker=${ticker}&country=${country}`}
        gaCategory="HOME"
        gaAction="HOME_STOCK_CLICK"
        gaLabel={`HOME_STOCK_${country}-${ticker}`}>
        <div className="container">
          <div className="image">
            {ticker && country && (
              <AssetImage ticker={ticker} country={country} alt={ticker || ''} />
            )}
          </div>
          <div className="--truncate meta">
            <div className="--truncate mark">
              {ticker && tickerOrName({ stock: company, locale: language })}
            </div>
            <div className="price">{`${company.currency?.unit}${threeComma({
              value: price,
              fixed: company.currency?.decimalPoint,
            })}`}</div>
            <div className="dividend">{`${threeComma({
              value: marketDividendRatioPercent,
              fixed: 2,
            })}%`}</div>
          </div>
        </div>
      </Link>
    </StyledStock>
  )
}

const RecentStocks = () => {
  const { t } = useLanguage('main')
  const companyIds = React.useMemo(() => getRecentStocks(), [])
  const { data, loading } = useCompanyDetailByIdsQuery({ variables: { companyIds } })
  const dividendsResp = useDividendHistoriesQuery({
    variables: { companyIds: companyIds || [] },
    skip: !companyIds,
  })
  // useCompanyDetailByIdsQuery data가 순서대로 내려주는게 아니라 별도 sort 로직 반영
  const sortedCompanies = React.useMemo(
    () =>
      data?.companyDetailByIds
        ? (companyIds.map((companyId) =>
            data?.companyDetailByIds?.find((company) => company.id === companyId),
          ) as CompanyDetailFieldsFragment[])
        : [],
    [companyIds, data?.companyDetailByIds],
  )
  const dividendHistoriesByCompany = React.useMemo(() => {
    const dividendHistories = dividendsResp.data
      ?.dividendHistories as DividendHistoryFieldsFragment[]
    return dividendHistories ? indexingDividendHistoiresByCompanyId({ dividendHistories }) : {}
  }, [dividendsResp.data?.dividendHistories])

  return (
    <StyledRecentStocks>
      <Spacing y="500" />
      <h2>{t('stock')}</h2>
      <Spacing y="500" />
      <div className="stocks">
        {loading && <Loading minHeight={80} />}
        <Carousel {...settings}>
          {sortedCompanies?.map((company) => (
            <Stock
              key={company.ticker}
              company={company}
              dividendHistories={dividendHistoriesByCompany[company.id as string]}
            />
          ))}
        </Carousel>
      </div>
      <Spacing y="500" />
    </StyledRecentStocks>
  )
}

export default RecentStocks
