import * as React from 'react'

import {
  AdPlatformEnum,
  AdTypeEnum,
  useAdsQuery,
  useClickAdMutation,
  useDoNotShowAdMutation,
  useMyAdsQuery,
} from '@/gql/generated-api1'
import { LocaleEnum } from '@/types/locale'

import { getInvisibleAds, setInvisibleAds } from './invisible-ads-as-term'

type Props = {
  language: LocaleEnum
  isMobile: null | boolean
  token: string
}

export type DoNotShowAd = ({
  adId,
  doNotShowUntil,
}: {
  adId: string
  doNotShowUntil: number
}) => void

const closedPopupIdsCache: string[] = [] // 그냥 닫기시 캐시

export default function useAds({ language, isMobile, token }: Props) {
  const [closedAdIds, setClosedAdIds] = React.useState(closedPopupIdsCache)
  const [invisibleAds, _setInvisibleAds] = React.useState(getInvisibleAds) // n일간 안보기 처리

  // 쿼리 data
  // useAdsQuery: 비로그인시 사용
  // useMyAdsQuery: 로그인시 사용
  const adsResp = useAdsQuery({
    variables: {
      adLocale: language,
      adPlatform: isMobile ? AdPlatformEnum.MobileWeb : AdPlatformEnum.PcWeb,
      adType: AdTypeEnum.Popup,
    },
    skip: isMobile === null || !!token,
  })
  const myAdsResp = useMyAdsQuery({
    variables: {
      adLocale: language,
      adPlatform: isMobile ? AdPlatformEnum.MobileWeb : AdPlatformEnum.PcWeb,
      adType: AdTypeEnum.Popup,
    },
    skip: isMobile === null || !token,
  })
  const ads = React.useMemo(() => {
    const ads = myAdsResp.data?.myAds || adsResp.data?.ads
    if (!ads?.length) return []
    return ads.filter((ad) => {
      return (
        !closedAdIds.includes(ad.id) &&
        (!invisibleAds[ad.id] || invisibleAds[ad.id] < new Date().getTime())
      )
    })
  }, [myAdsResp.data?.myAds, adsResp.data?.ads, closedAdIds, invisibleAds])

  // 핸들러 뮤테이션
  // n일간 보지 않기는 doNotShowAdMutation 호출과 상관없이 로컬스토리지로 클라이언트 핸들링(어차피 비로그인시 처리는 무조건 클라이언트에서 처리해야 하기 때문에)
  const [doNotShowAdMutation] = useDoNotShowAdMutation()
  const [clickAdMutation] = useClickAdMutation()
  const handleCloseAd = React.useCallback(
    (adId: string) => {
      if (adId) {
        closedPopupIdsCache.push(adId)
        setClosedAdIds((adIds) => [...adIds, adId])
      }
    },
    [setClosedAdIds],
  )
  const doNotShowAd: DoNotShowAd = React.useCallback(
    ({ adId, doNotShowUntil }) => {
      doNotShowAdMutation({ variables: { adId } })
      const ms = new Date().getTime() + doNotShowUntil * 60 * 60 * 24 * 100
      setInvisibleAds([{ adId, ms }])
      _setInvisibleAds((invisibleAds) => ({ ...invisibleAds, [adId]: ms }))
    },
    [doNotShowAdMutation],
  )
  const handleClickAd = React.useCallback(
    (adId: string) => {
      if (adId) {
        clickAdMutation({ variables: { adId } })
        handleCloseAd(adId)
      }
    },
    [clickAdMutation, handleCloseAd],
  )

  return {
    ads,
    doNotShowAd,
    handleClickAd,
    handleCloseAd,
    loading: adsResp.loading || myAdsResp.loading,
  }
}
