import Big from 'big.js'

import threeComma from '@/utils/number/three-comma'

type Props = {
  dividendAamount?: number | null
  paymentMonths?: number[] | null
  price?: string | null
  hasUnit?: boolean
}

// 연간 배당률(시가 배당률)
export default function dividendYieldPerAnnually({
  dividendAamount,
  paymentMonths,
  price,
  hasUnit = true,
}: Props): string {
  const dividendYieldPA = Big(dividendAamount ?? 0)
    .mul(paymentMonths?.length ?? 0)
    .div(price ?? 0)
    .mul(100)
    .toFixed(2)

  if (hasUnit) {
    return `${threeComma({ value: dividendYieldPA })}%`
  }

  return dividendYieldPA
}
