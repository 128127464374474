import * as React from 'react'

import type { Menu } from '@/components/shape/news/news-nav'
import NewsList from '@/components/shape/news/news-list'
import useNewsByType from '@/service/news/news-by-type-hook'
import { SpacerY } from '@/design-system/spacer'
import { useMobileSize } from '@/utils/browser/resize-hook'

type Props = {
  selectedMenu: Menu
  countryCode: string
  moreView: JSX.Element
}

const TodayNewsList = ({ selectedMenu, countryCode, moreView }: Props) => {
  const { news, loading, isRequireSignin, isRequirePortfolios } = useNewsByType({
    type: selectedMenu,
    country: countryCode,
  })
  const limitedNews = React.useMemo(() => news.slice(0, 4), [news])
  const { isMobile } = useMobileSize()

  return (
    <>
      <NewsList
        news={limitedNews}
        loading={!news?.length && loading}
        isRequireSignin={isRequireSignin}
        isRequirePortfolios={isRequirePortfolios}
        hasDesktopStyle
      />
      {isMobile && !!news?.length && (
        <>
          <SpacerY size={20} />
          {moreView}
        </>
      )}
    </>
  )
}

export default TodayNewsList
