import { getLocalStorage, setLocalStorage } from '@/utils/browser/localstorage'
import { LocalStorageEnum } from '@/constants/localstorage'

// const defaultList = ['AAPL', 'T', 'V', 'KO', 'MSFT', 'O', 'AMZN', 'TSLA', 'MCD', 'GOOGL']
const defaultList = ['1', '2', '48', '4', '7', '3', '9', '51', '12', '123']

export function addRecentStock(companyId: string[] | string) {
  const recentStocks = getRecentStocks()
  const savedCompanyIds = typeof recentStocks === 'string' ? [] : recentStocks
  const companyIds = typeof companyId === 'string' ? [companyId] : companyId
  setLocalStorage({
    key: LocalStorageEnum.RecentStocks,
    value: Array.from(new Set([...companyIds, ...savedCompanyIds])).slice(0, 20),
  })
}

export function getRecentStocks() {
  const recentStocks = getLocalStorage<string[]>({
    key: LocalStorageEnum.RecentStocks,
    type: 'array',
    fallbackValue: defaultList,
  }) as string[] | undefined

  return !recentStocks || typeof recentStocks === 'string' ? defaultList : recentStocks
}
