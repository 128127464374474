import styled from 'styled-components'

const StyledHomePortfolios = styled.div`
  padding: var(--spacing-legacy-10);
  padding-bottom: 0;
  height: calc(100% - 1rem);
  margin-right: var(--spacing-legacy-12);
  border-radius: var(--border-radius-6);

  @media (min-width: 768px) {
    background-color: var(--color-gray-27);
  }

  @media (max-width: 767px) {
    margin-right: 0;
    width: auto;
    height: auto;
    border-radius: 0;
    padding: var(--spacing-legacy-15) 0;
    padding-right: 0;
  }

  h2 {
    color: var(--color-gray-207);
    font-size: var(--font-size-14);
    margin-bottom: var(--spacing-legacy-10);

    @media (max-width: 767px) {
      padding: 0 var(--spacing-legacy-15);
      margin-bottom: var(--spacing-legacy-20);
      font-size: var(--font-size-18);
    }
  }

  .desc {
    color: var(--color-primary);
    font-size: var(--font-size-12);
    text-align: center;
    padding: 2.7rem 0;

    @media (max-width: 767px) {
      padding: var(--spacing-30) 0;
    }

    button {
      color: var(--color-white);
      text-decoration: underline;
    }
  }
`

export default StyledHomePortfolios
