import styled from 'styled-components'

const StyledTodayNewsNotification = styled.button`
  margin-right: var(--spacing-legacy-16);
  font-size: var(--font-size-16);
  padding: var(--spacing-legacy-8);
  border-radius: var(--border-radius-12);
  transition: background-color 0.2s;
  will-change: background-color;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: var(--color-gray-32);
    }
  }

  .alarm-image {
    margin-right: var(--spacing-legacy-8);
    width: var(--spacing-legacy-16);
    height: var(--spacing-legacy-16);
  }

  @media (max-width: 767px) {
    margin: 0 var(--spacing-legacy-10);
    width: calc(100% - var(--spacing-legacy-20));
    background-color: var(--color-gray-32);
  }
`

export default StyledTodayNewsNotification
