import styled from 'styled-components'

import { getSizeBy375 } from '@/utils/style/size'

const StyledRecentStocks = styled.div`
  @media (min-width: 768px) {
    border-radius: var(--border-radius-6);
    background-color: var(--color-gray-27);
  }

  @media (max-width: 767px) {
    border-radius: 0;
  }

  h2 {
    padding: 0 var(--spacing-legacy-15);
  }

  .stocks {
    padding: 0 var(--spacing-legacy-15);

    @media (max-width: 767px) {
      padding: 0;
    }
  }
`

export default StyledRecentStocks

export const StyledStock = styled.div`
  padding: var(--spacing-legacy-10) 0;
  background: var(--linear-gradient-37-27);
  border-radius: var(--border-radius-12);
  margin-right: var(--spacing-legacy-10);

  @media (max-width: 767px) {
    padding: var(--spacing-legacy-20) 0;
    width: ${getSizeBy375(105)};
    flex-shrink: 0;
    margin-right: 0;

    :first-child {
      margin-left: var(--spacing-legacy-20);
    }

    :last-child {
      margin-right: var(--spacing-legacy-20);
    }
  }

  a {
    @media (max-width: 767px) {
      text-align: center;
    }

    @media (min-width: 768px) {
      .container {
        display: flex;
      }
    }

    .image {
      flex-shrink: 0;
      display: inline-block;
      width: var(--spacing-legacy-50);
      height: var(--spacing-legacy-50);
      margin: auto var(--spacing-legacy-15);

      @media (max-width: 767px) {
        margin-bottom: var(--spacing-legacy-10);
      }
    }

    .meta {
      display: inline-block;

      @media (max-width: 767px) {
        width: calc(100% - (var(--spacing-legacy-20) * 2));
      }

      .mark {
        color: var(--color-primary);
        font-size: var(--font-size-18);
      }

      .price {
        color: var(--color-white);
        margin-top: var(--spacing-legacy-8);
        font-size: var(--spacing-legacy-13);
      }

      .dividend {
        color: var(--color-white);
        margin-top: var(--spacing-legacy-5);
        font-size: var(--font-size-16);
      }
    }
  }
`
