import { Heading, Spacing } from '@therich-team/design-system'
import * as React from 'react'

import StyledHomeContentLayout from './styled'

type Props = {
  children: React.ReactNode
  title: string
  rightContent?: React.ReactNode
}

const HomeContentLayout = ({ children, title, rightContent }: Props) => {
  return (
    <StyledHomeContentLayout>
      <Spacing y="500" />
      <div className="header --flex-x-y-between">
        <Heading size={5}>{title}</Heading>
        {rightContent}
      </div>
      <Spacing y="500" />
      <div className="contents">{children}</div>
      <Spacing y="500" />
    </StyledHomeContentLayout>
  )
}

export default HomeContentLayout
