import styled from 'styled-components'

export const StyledFullscreen = styled.div`
  z-index: var(--z-10000002);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const StyledLoadingContainer = styled.div`
  z-index: var(--z-10000002);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledLoading = styled.div`
  width: 40px;

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    margin: 0 -5%;
    overflow: hidden;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing 0.3s infinite linear alternate;
    animation-delay: 0s;
  }

  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing 0.3s infinite linear alternate;
    animation-delay: 0.1s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    animation: dot-flashing 0.3s infinite linear alternate;
    animation-delay: 0.2s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #ffffff7d;
    }
    50%,
    100% {
      background-color: #00000055;
    }
  }
`

export default StyledLoading
