import styled from 'styled-components'

const StyledMbtiBanner = styled.div`
  background-color: var(--color-gray-27);
  border-radius: var(--border-radius-6);
  height: 100%;

  @media (max-width: 767px) {
    padding: 0 var(--spacing-legacy-20);
    border-radius: 0;
  }

  a {
    display: block;
    width: 275px;
    height: 100px;

    @media (max-width: 767px) {
      width: 100%;
      height: auto;
    }
  }
`

export default StyledMbtiBanner
