import * as React from 'react'
import isEmpty from 'lodash/fp/isEmpty'

import stockImage from '@/service/stock/image'
import cryptoImage from '@/service/crypto/image'
import withClientOnly from '@/components/common/client-only/hoc'

import StyledAssetImage from './styled'

type Props = {
  imageUrl?: string
  fallbackUrl?: string
  ticker?: string
  country?: string
  base?: string
  alt?: string
  wrapperRef?: React.RefObject<HTMLElement>
  className?: string
}

const AssetImage = ({
  imageUrl,
  ticker,
  country,
  base,
  alt,
  className,
  wrapperRef,
  fallbackUrl,
}: Props) => {
  const src = React.useMemo(() => {
    if (imageUrl) return imageUrl

    if (ticker && country) {
      return stockImage({ ticker, country })
    }

    if (base) {
      return cryptoImage({ base })
    }

    return ''
  }, [imageUrl, ticker, country, base])

  return src ? (
    <StyledAssetImage
      className={className}
      src={src}
      alt={alt || ticker || base || ''}
      loading="lazy"
      onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
        if (wrapperRef?.current?.style) {
          wrapperRef.current.style.display = ''
        } else {
          e.currentTarget.style.display = 'block'
        }
        e.currentTarget.style.opacity = '1'
      }}
      onError={(e) => {
        // 상위 엘리먼트가 전달된 경우 상위 엘리먼트를 hidden 시킴
        if (wrapperRef?.current?.style) {
          wrapperRef.current.style.display = 'none'
        } else {
          if (isEmpty(fallbackUrl)) {
            e.currentTarget.style.display = 'none'
          } else {
            e.currentTarget.src = fallbackUrl
          }
        }
      }}
    />
  ) : null
}

// withClientOnly: SSR시 onLoad가 실행되지 않는 현상 대응
export default withClientOnly(AssetImage)
