import * as React from 'react'
import { useTranslation } from 'react-i18next'

import type { DividendHistoryApi1FieldsFragment } from '@/gql/generated-api1'
import withFetchEffect from '@/components/common/fetch-effect-hoc'
import Carousel from '@/components/common/carousel'
import dividendDDay, { DDay } from '@/service/dividend/dividend-d-day'
import tickerOrName from '@/service/stock/ticker-or-name'
import dividendYieldPerAnnually from '@/service/dividend/yield-per-annually'
import useCurrencyExchanges from '@/service/currency/currency-exchanges-hook'

import Stock from '../stock'

import StyledStocks, { NoResult } from './styled'

type Props = {
  dividendKing: string[]
  dividendAristocrats: string[]
  dividends?: DividendHistoryApi1FieldsFragment[] | null
}

const Stocks = ({ dividends, dividendKing, dividendAristocrats }: Props) => {
  const { t } = useTranslation('main')
  const { exchangeCurrency } = useCurrencyExchanges()
  const { indexedKing, indexedAristocrats } = React.useMemo(() => {
    const indexedKing =
      dividendKing?.reduce((acc: Record<string, string>, cur) => {
        acc[cur] = cur
        return acc
      }, {}) ?? {}
    const indexedAristocrats =
      dividendAristocrats?.reduce((acc: Record<string, string>, cur) => {
        acc[cur] = cur
        return acc
      }, {}) ?? {}

    return { indexedKing, indexedAristocrats }
  }, [dividendKing, dividendAristocrats])

  const filteredDividends = React.useMemo(() => {
    if (!dividends) return []

    return dividends?.reduce(
      (
        acc: (DividendHistoryApi1FieldsFragment & { dDay: DDay; dividendRatioPercent: string })[],
        dividend,
      ) => {
        if (!dividend.exDividendDate) return acc

        const dDay = dividendDDay({ exDividendDate: dividend.exDividendDate })

        const dividendRatioPercent = dividendYieldPerAnnually({
          dividendAamount: +exchangeCurrency({
            price: dividend.amount ?? 0,
            from: dividend.currency?.symbol,
            to: dividend.company.currency?.symbol,
          }),
          paymentMonths: dividend.company?.paymentMonths,
          price: dividend.company?.companyPrice?.price,
          hasUnit: false,
        })

        if (dDay !== null && dDay > -1 && +dividendRatioPercent > 0) {
          acc.push({ ...dividend, dDay, dividendRatioPercent })
        }

        return acc
      },
      [],
    )
  }, [dividends, exchangeCurrency])

  if (!Array.isArray(filteredDividends) || !filteredDividends?.length) {
    return <NoResult className="--flex-x-y-center">{t('dividends.noResult')}</NoResult>
  }

  return (
    <StyledStocks>
      <Carousel>
        {filteredDividends.map((dividend) => (
          <Stock
            key={dividend.id}
            dDay={dividend.dDay}
            ticker={dividend.company?.ticker as string}
            country={dividend.company?.country as string}
            logoUrl={dividend.company?.logoUrl as string}
            name={dividend.company ? tickerOrName({ stock: dividend.company }) : ''}
            dividendRatioPercent={dividend.dividendRatioPercent}
            isKing={!!indexedKing[dividend.company?.ticker as string]}
            isAristocrats={!!indexedAristocrats[dividend.company?.ticker as string]}
          />
        ))}
      </Carousel>
    </StyledStocks>
  )
}

export default withFetchEffect<Props>(Stocks)
