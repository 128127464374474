import * as React from 'react'
import ReactDOM from 'react-dom'
import type { CSSProperties } from 'styled-components'

import { useMobileSize } from '@/utils/browser/resize-hook'
import { getSizeBy375 } from '@/utils/style/size'

import StyledLoading, { StyledFullscreen, StyledLoadingContainer } from './styled'

type Props = {
  isLoading?: boolean
  minHeight?: string | number
  isFullscreen?: boolean
  position?: 'absolute'
}

const Portal = ({ children }: { children: JSX.Element }) => {
  return global.document?.body ? ReactDOM.createPortal(children, document.body) : children
}

const Loading = React.forwardRef<HTMLDivElement, Props>(
  ({ isLoading = true, minHeight = '', isFullscreen = true, position }, ref) => {
    const { isMobile } = useMobileSize()

    const Wrapper = React.useCallback(
      ({ children, ...props }: { children: JSX.Element; style: CSSProperties }) =>
        isFullscreen && !minHeight && !position ? (
          <Portal>
            <StyledFullscreen {...props}>{children}</StyledFullscreen>
          </Portal>
        ) : (
          <StyledLoadingContainer {...props}>{children}</StyledLoadingContainer>
        ),
      [isFullscreen, minHeight, position],
    )

    const _minHeight = React.useMemo(
      () =>
        minHeight && typeof minHeight === 'number'
          ? isMobile
            ? getSizeBy375(minHeight)
            : `${minHeight}px`
          : minHeight,
      [minHeight, isMobile],
    )

    const style = React.useMemo<React.CSSProperties>(() => {
      const positionStyle =
        position === 'absolute'
          ? ({ position: 'absolute', top: '0', bottom: '0', right: '0', left: '0' } as const)
          : {}
      return { minHeight: _minHeight, ...positionStyle }
    }, [_minHeight, position])

    return isLoading ? (
      <Wrapper style={style}>
        <StyledLoading ref={ref}>
          <div className="stage">
            <div className="dot-flashing"></div>
          </div>
        </StyledLoading>
      </Wrapper>
    ) : null
  },
)

Loading.displayName = 'Loading'

export default Loading
