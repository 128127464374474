import * as React from 'react'
import type { ApolloError } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Loading from '@/components/common/loading'

import { StyledError } from './styled'

type HocProps = {
  loading: boolean
  error?: ApolloError
  loadingBarHeight?: number | string
}

type ComponentProps<P> = Omit<P, 'loadingBarHeight' | 'loading' | 'error'>

export default function withFetchEffect<P extends Record<string, unknown>>(
  Component: React.FC<ComponentProps<P>>,
): React.FC<P & HocProps> {
  return function FetchHOC(props: P & HocProps) {
    const { loading, error, loadingBarHeight = 100, ...restProps } = props
    const { t } = useTranslation('common')

    if (loading) {
      return <Loading isLoading={loading} minHeight={loadingBarHeight} />
    }

    if (error) {
      return (
        <StyledError>
          <p>{t('network.error')}</p>
          <p>{error?.message}</p>
        </StyledError>
      )
    }

    return <Component {...restProps} />
  }
}
