import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

export const NoResult = styled.div`
  height: 18.6rem;
  font-size: var(--font-size-16);

  @media (max-width: 767px) {
    height: ${getSize(186)};
  }
`

const StyledStocks = styled.div`
  @media (max-width: 767px) {
    .--horizontal-scroll {
      padding-left: var(--spacing-legacy-20);
      padding-right: var(--spacing-legacy-20);
    }
  }
`

export default StyledStocks
