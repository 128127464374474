import { useIsKoreanLocale } from '@/utils/language'
import Image from '@/components/common/image'
import { ExternalLink } from '@/components/common/link'

import StyledMbtiBanner from './styled'

const MbtiBanner = () => {
  const isKoreanLocale = useIsKoreanLocale()

  return (
    <StyledMbtiBanner className="--flex-x-y-center">
      <ExternalLink
        href={
          isKoreanLocale
            ? 'https://www.therich.io/mbti-test/main'
            : 'https://www.therich.io/en/mbti-test/main'
        }
        target="_blank"
        rel="noopener noreferrer"
        gaCategory="HOME"
        gaAction="HOME_MBTI_CLICK">
        <Image
          src={isKoreanLocale ? '/images/mbti-banner-ko.png' : '/images/mbti-banner-en.png'}
          alt="mbti banner"
          width={640}
          height={227}
          layout="responsive"
          priority
        />
      </ExternalLink>
    </StyledMbtiBanner>
  )
}

export default MbtiBanner
