import * as React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

import type { AdFieldsFragment } from '@/gql/generated-api1'
import type { DoNotShowAd } from '@/service/advertisement/ads-hook'
import { BottomModal } from '@/components/common/modal'
import useLanguage from '@/utils/language/language-hook'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'
import { ModalBaseProps } from '@/components/common/modal'

import PopupContent from '../popup-content'

import StyledPopupsMobileSheet from './styled'

type Props = {
  isOpen: ModalBaseProps['isOpen']
  popups: AdFieldsFragment[]
  doNotShowAd: DoNotShowAd
  onCloseAd: (adId: string) => void
  onClickAd: (adId: string) => void
}

const MobilePopups = ({ popups, doNotShowAd, onCloseAd, onClickAd }: Props) => {
  const splideRef = React.useRef<Splide>(null)
  const { t } = useLanguage('advertisement')
  const [visiblePopup, setVisiblePopup] = React.useState(popups[0])

  React.useEffect(() => {
    // onActive 만으로는 렌더 시점과 onActive 시점이 다르므로 활성화된 팝업 정보를 특정할 수 없으므로 해결책으로 작성된 로직
    // onActive 는 이벤트로 넘길때 사용
    // 해당 useEffect는 닫기 클릭했을 경우에 사용
    if (popups.length && splideRef.current?.splide) {
      setVisiblePopup(popups[splideRef.current.splide.index])
    }
  }, [popups])

  return (
    <StyledPopupsMobileSheet>
      <Splide
        ref={splideRef}
        options={{ arrows: false }}
        hasTrack={false}
        onActive={(_, component) => {
          const popup = popups[component.index]
          if (popup) {
            setVisiblePopup(popup)
          }
        }}>
        <SplideTrack>
          {popups.map((popup) => (
            <SplideSlide key={popup.id}>
              <div className="image-content">
                <PopupContent
                  adId={visiblePopup.id}
                  url={popup.adClickUrl}
                  content={popup.adContent}
                  onClick={() => onClickAd(popup.id)}
                />
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
      <div className="--flex-x-y-between bottom-buttons">
        <button
          type="button"
          onClick={() => {
            doNotShowAd({ adId: visiblePopup.id, doNotShowUntil: visiblePopup.doNotShowUntil })
            sendGaCustomEvent({
              gaCategory: 'AD',
              gaAction: 'AD_POPUP-INVISIBLE_CLICK',
              gaLabel: `AD_POPUP-INVISIBLE_${visiblePopup.id}`,
            })
          }}>
          {visiblePopup.doNotShowUntil === 3650
            ? t('neverSee')
            : t('notWatch', { days: visiblePopup.doNotShowUntil })}
        </button>
        <button
          type="button"
          onClick={() => {
            onCloseAd(visiblePopup.id)
            sendGaCustomEvent({
              gaCategory: 'AD',
              gaAction: 'AD_POPUP-CLOSE_CLICK',
              gaLabel: `AD_POPUP-CLOSE_${visiblePopup.id}`,
            })
          }}>
          {t('close')}
        </button>
      </div>
    </StyledPopupsMobileSheet>
  )
}

const MobilePopupsModal = (props: Props) => {
  const [isShowPopups, closeAllPopups] = React.useReducer((is) => !is, true)
  return (
    <BottomModal isOpen={props.isOpen && isShowPopups} onClose={closeAllPopups}>
      {!!props.popups.length && <MobilePopups {...props} />}
    </BottomModal>
  )
}

export default MobilePopupsModal
