import * as React from 'react'
import { Body } from '@therich-team/design-system'

import Link, { LinkProps } from '@/components/common/link'

type Props = LinkProps & {
  children: string
}

const HomeContentLayoutLink = ({ children, ...props }: Props) => {
  return (
    <Link {...props}>
      <Body size={1}>{children}</Body>
    </Link>
  )
}

export default HomeContentLayoutLink
