import * as React from 'react'
import dynamic from 'next/dynamic'

import useLanguage from '@/utils/language/language-hook'
import sendGaEvent from '@/service/event-log/gtag-custom-event'
import { useAuthTokenStore } from '@/service/auth/store'

import StyledHomePortfolios from './styled'

const PortfolioAddPortfolio = dynamic(
  () => import('@/components/pages/my-portfolios/my-portfolios-add-portfolio'),
  { ssr: false },
)
const MyPortfolios = dynamic(() => import('./my-portfolios'), { ssr: false })

const HomePortfolios = () => {
  const { t } = useLanguage('main')
  const { token } = useAuthTokenStore()
  const [isOpenModal, setIsOpenModal] = React.useState(false)

  const openModal = React.useCallback(() => {
    sendGaEvent({
      gaCategory: 'HOME',
      gaAction: 'HOME_ADD-MY-PORTFOLIO_CLICK',
    })
    setIsOpenModal(true)
  }, [])

  return (
    <StyledHomePortfolios>
      <h2>{t('portfolio.title')}</h2>
      {token ? (
        <MyPortfolios openModal={openModal} />
      ) : (
        <p className="desc">{t('portfolio.signinRequired')}</p>
      )}
      <PortfolioAddPortfolio isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </StyledHomePortfolios>
  )
}

export default HomePortfolios
