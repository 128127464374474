import * as React from 'react'
import { Adsense } from '@ctrl/react-adsense'

import useResize from '@/utils/browser/resize-hook'

const Advertisement = () => {
  const [isVisible, setIsVisible] = React.useState(true)
  const deviceWidthRef = React.useRef(global?.window?.innerWidth)
  const timeoutIdRef = React.useRef<NodeJS.Timeout | null>(null)
  const handleResize = React.useCallback(() => {
    // width가 변경될 경우에만 사용
    if (
      typeof global?.window?.innerWidth !== 'undefined' &&
      deviceWidthRef.current !== global.window.innerWidth
    ) {
      deviceWidthRef.current = global?.window.innerWidth
      if (timeoutIdRef.current) {
        global?.clearTimeout(timeoutIdRef.current)
      }

      setIsVisible(false)
      timeoutIdRef.current = global?.setTimeout(() => {
        setIsVisible(true)
        timeoutIdRef.current = null
      }, 1000)
    }
  }, [])
  useResize({ onResize: handleResize })

  return isVisible ? (
    <div className="advertisement-container">
      <div className="--sticky">
        <Adsense
          className="adsbygoogle"
          style={{ display: 'block' }}
          format="auto"
          client="ca-pub-3273351027070330"
          slot="2762611746"
          responsive="true"
        />
      </div>
    </div>
  ) : null
}

export default Advertisement
