import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Link from '@/components/common/link'
import Image from '@/components/common/image'
import Carousel from '@/components/common/carousel'
import { useIsKoreanLocale } from '@/utils/language'
import HomeContentLayout from '@/components/layout/home-content-layout'

import StyledRecommendedPortfolios from './styled'

const BILLIONAIRES_IMAGE_URL = 'https://images.therich.io/billionaires/'

const BILLIONAIRES = [
  {
    title_ko: '워렌버핏',
    title_en: 'Warren Buffett',
    portfolioId: 4902,
    slug: '4eypzqbpwzu',
  },
  {
    title_ko: '조지 소로스',
    title_en: 'George Soros',
    portfolioId: 4904,
    slug: '4eytr4rhigx',
  },
  {
    title_ko: '레이 달리오',
    title_en: 'Ray Dalio',
    portfolioId: 4906,
    slug: 'fwa6h8p892',
  },
  {
    title_ko: '빌 애크먼',
    title_en: 'Bill Ackman',
    portfolioId: 4918,
    slug: '4eytuwffjm3',
  },
  {
    title_ko: '래리 로빈스',
    title_en: 'Larry Robbins',
    portfolioId: 4916,
    slug: '4eytvspwh17',
  },
  {
    title_ko: '데이빗 테퍼',
    title_en: 'David Tepper',
    portfolioId: 4908,
    slug: '4eytybk9xtp',
  },
  {
    title_ko: '폴 싱어',
    title_en: 'Paul Singer',
    portfolioId: 4912,
    slug: '4eyujuhe1ps',
  },
  {
    title_ko: '존 폴슨',
    title_en: 'John Paulson',
    portfolioId: 4907,
    slug: '4eyu0hnvfko',
  },
  {
    title_ko: '리온 쿠퍼맨',
    title_en: 'Leon Cooperman',
    portfolioId: 4910,
    slug: '4eyu3733inl',
  },
  {
    title_ko: '줄리안 로버트슨',
    title_en: 'Julian Robertson',
    portfolioId: 4911,
    slug: '4eyu7vx46g1',
  },
  {
    title_ko: '다이넬 로엡',
    title_en: 'Daniel Loeb',
    portfolioId: 4913,
    slug: '4eyua3wgblg',
  },
  {
    title_ko: '스티븐 만델',
    title_en: 'Stephen Mandel',
    portfolioId: 4914,
    slug: '4eyub9l9wwt',
  },
  {
    title_ko: '넬슨 펠츠',
    title_en: 'Nelson Peltz',
    portfolioId: 4915,
    slug: '4eyucd18fab',
  },
  {
    title_ko: '데이비드 아인호른',
    title_en: 'David Einhorn',
    portfolioId: 4919,
    slug: '4eyufammqdt',
  },
  {
    title_ko: '체이스 콜먼',
    title_en: 'Chase Coleman III',
    portfolioId: 4920,
    slug: '4eyuga6xiyw',
  },
  {
    title_ko: '로버트 시트론',
    title_en: 'Robert Citrone',
    portfolioId: 4921,
    slug: '4eyuh4wfyvs',
  },
]

const crouselSetting = {
  dots: false,
  slidesToShow: 10.5,
  slidesToScroll: 9,
}

const RecommendedPortfolios = () => {
  const { t } = useTranslation('main')
  const isKoreanLocale = useIsKoreanLocale()

  return (
    <HomeContentLayout title={t('billionaires')}>
      <StyledRecommendedPortfolios>
        <Carousel {...crouselSetting}>
          {BILLIONAIRES.map((item) => (
            <Link
              key={item.portfolioId}
              href={`/favorite-item-category/${item.slug}`}
              gaCategory="HOME"
              gaAction="HOME_BILLIONAIRE-INVESTING_CLICK"
              gaLabel={`HOME_BILLIONAIRE-INVESTING_${item.slug}-${item.title_ko}`}>
              <div className="image">
                <Image
                  src={`${BILLIONAIRES_IMAGE_URL}billionaire_${item.portfolioId}.png`}
                  alt={isKoreanLocale ? item.title_ko : item.title_en}
                  layout="responsive"
                  width={154}
                  height={154}
                />
              </div>
              <span>{isKoreanLocale ? item.title_ko : item.title_en}</span>
            </Link>
          ))}
        </Carousel>
      </StyledRecommendedPortfolios>
    </HomeContentLayout>
  )
}

export default RecommendedPortfolios
