import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const StyledCarousel = styled.div`
  @media (max-width: 767px) {
    > *:not(:first-child) {
      margin-left: ${getSize(10)};
    }
  }

  .prev,
  .next {
    z-index: var(--z-1);
    position: absolute;
    top: 50%;
    background-color: var(--color-gray-32);
    border-radius: 50%;
    width: var(--spacing-legacy-40);
    height: var(--spacing-legacy-40);
    box-shadow: var(--shadow-bottom);
    transform-origin: top;
    transition: opacity 0.2s, transform 0.2s;
    opacity: 0.9;

    :disabled {
      opacity: 0;
      transform: scale(0);
    }

    @media (hover: hover) and (pointer: fine) {
      :hover {
      }
    }

    div {
      display: inline-block;
      width: 50%;
      height: 50%;
    }
  }

  .next {
    right: 0;
    transform: scale(1) translate(30%, -50%);

    > div {
      transform: rotateZ(-90deg);
    }
  }

  .prev {
    left: 0;
    transform: scale(1) translate(-30%, -50%);

    > div {
      transform: rotateZ(90deg);
    }
  }

  .slick-track {
    margin: 0;
  }
`

export default StyledCarousel
