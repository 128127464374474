import * as React from 'react'

import type { AdFieldsFragment } from '@/gql/generated-api1'
import type { DoNotShowAd } from '@/service/advertisement/ads-hook'
import { ModalContainer, ModalBaseProps } from '@/components/common/modal'
import useLanguage from '@/utils/language/language-hook'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'

import PopupContent from '../popup-content'

import StyledPopupsDesktop from './styled'

type Props = {
  isOpen: ModalBaseProps['isOpen']
  popups: AdFieldsFragment[]
  doNotShowAd: DoNotShowAd
  onCloseAd: (adId: string) => void
  onClickAd: (adId: string) => void
}

const DesktopPopups = ({ isOpen, popups, doNotShowAd, onCloseAd, onClickAd }: Props) => {
  const { t } = useLanguage('advertisement')
  const [isShowPopups, closeAllPopups] = React.useReducer((is) => !is, true)
  const visiblePopup = popups[0]

  return (
    <ModalContainer isOpen={isOpen && isShowPopups} pcWidth={400} onClose={closeAllPopups}>
      {visiblePopup && (
        <StyledPopupsDesktop>
          <PopupContent
            adId={visiblePopup.id}
            url={visiblePopup.adClickUrl}
            content={visiblePopup.adContent}
            onClick={() => onClickAd(visiblePopup.id)}
          />
          <div className="--flex-x-y-between bottom-buttons">
            <button
              type="button"
              onClick={() => {
                doNotShowAd({ adId: visiblePopup.id, doNotShowUntil: visiblePopup.doNotShowUntil })
                sendGaCustomEvent({
                  gaCategory: 'AD',
                  gaAction: 'AD_POPUP-INVISIBLE_CLICK',
                  gaLabel: `AD_POPUP-INVISIBLE_${visiblePopup.id}`,
                })
              }}>
              {visiblePopup.doNotShowUntil === 3650
                ? t('neverSee')
                : t('notWatch', { days: visiblePopup.doNotShowUntil })}
            </button>
            <button
              type="button"
              onClick={() => {
                onCloseAd(visiblePopup.id)
                sendGaCustomEvent({
                  gaCategory: 'AD',
                  gaAction: 'AD_POPUP-CLOSE_CLICK',
                  gaLabel: `AD_POPUP-CLOSE_${visiblePopup.id}`,
                })
              }}>
              {t('close')}
            </button>
          </div>
        </StyledPopupsDesktop>
      )}
    </ModalContainer>
  )
}

export default DesktopPopups
