import styled from 'styled-components'

const StyledPopupsMobileSheet = styled.div`
  background: linear-gradient(180deg, var(--color-gray-46) 0%, var(--color-gray-19) 80%);

  .image-content {
    overflow: hidden;
  }

  /* 페이지네이션 */
  .splide__pagination {
    bottom: calc(var(--spacing-legacy-30) * -1);

    .splide__pagination__page {
      background: var(--color-gray-196-a2);
      width: var(--spacing-legacy-5);
      height: var(--spacing-legacy-5);

      &.is-active {
        background: var(--color-gray-196);
        transform: scale(1);
      }
    }
  }

  .bottom-buttons {
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--spacing-legacy-50);

    button {
      color: var(--color-gray-128);
      padding: var(--spacing-legacy-30) var(--spacing-legacy-20);
      font-size: var(--font-size-16);

      @media (max-width: 767px) {
        padding: var(--spacing-legacy-15) var(--spacing-legacy-25);
        font-size: var(--font-size-13);
      }
    }
  }
`

export default StyledPopupsMobileSheet
