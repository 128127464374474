import styled from 'styled-components'

const StyledAssetImage = styled.img`
  display: inline-block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.2s;
`

export default StyledAssetImage
